import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const digilex_title = "digiLex"
const chronora_title = "chronOra"
const ticktrack_title = "tickTrack"
const prodocs_title = "prodocs"
const lenscape_title = "lenscape"

export  const getGatsbyStaticImage = (title) => {

    let res
    const image_style = `
    !absolute w-full h-full transition group-hover:scale-105 duration-500
    object-cover object-center top-0 bottom-0 left-0 right-0 z-0 opacity-30 md:opacity-60 group-hover:opacity-30`

    switch (title?.toLowerCase()) {
        case "digilex":
            res = 
            <StaticImage
            className={image_style}
            src="../../static/prodotti/digilex.jpg"
            alt={digilex_title}
            placeholder="blurred"
            />
        break

        case "chronora":
            res = 
            <StaticImage
            className={image_style}
            src="../../static/prodotti/chronora.jpg"
            alt={chronora_title}
            placeholder="blurred"
            />
            break

        case "ticktrack":
            res = 
            <StaticImage
            className={image_style}
            src="../../static/prodotti/ticktrack.jpg"
            alt={ticktrack_title}
            placeholder="blurred"
            />
            break

        case "prodocs":
            res = 
            <StaticImage
            className={image_style}
            src="../../static/prodotti/prodocs.jpg"
            alt={prodocs_title}
            placeholder="blurred"
            />
            break

        case "lenscape":
            res =
            <StaticImage
            className={image_style}
            src="../../static/prodotti/lenscape.jpg"
            alt={lenscape_title}
            placeholder="blurred"
            />
            break

        default:
            res = null
            break
    }
    return res
}