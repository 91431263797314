import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
import {getGatsbyStaticImage} from "../../constants/products-image"

const FeaturedProduct = ({ feature }) => {

  const { gatsbyPath, title, preview } = feature
  const text_shadow={textShadow:"0px 0px 5px rgba(0, 0, 0, 1)"}

  const gatsbyStaticImage = getGatsbyStaticImage(title)

  return (
    <div className={`group min-h-[408px] sm:min-h-[434px] md:min-h-[500px] lg:min-h-[600px] w-full
    bg-black overflow-hidden relative rounded-lg border-2 border-zinc-600 
    hover:border-yellow-500 shadow-lg hover:shadow-black flex justify-between duration-500`}>
      
      <Link to={gatsbyPath}>
        
        {gatsbyStaticImage && gatsbyStaticImage}
      
        {title && preview && (
          
          <div className="w-full absolute z-10 bottom-0 left-0 right-0 px-5 pb-10">
            {
              title && 
              <h3 style={text_shadow}
              className={`text-white font-bold capitalize text-xl md:text-2xl xl:text-3xl`}>
                {title}
              </h3>
            }
            {
              preview && 
              <p style={text_shadow} className="text-sm md:text-base mt-4 mb-3 text-slate-200 font-extralight">
                {preview}
              </p>
            }
            <Button text="Read More" as="span" arrow={true} />
          </div>

        )}
      
      </Link>
    </div >
  )
}

export default FeaturedProduct
