import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Products } from "../constants/products"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import FeaturedProduct from "../components/Features/FeaturedProduct"
// import Button from "../components/Button/Button"

const contact = () => {

  const children = 
    <StaticImage
    className="!absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80"
    imgClassName="absolute top-0 bottom-0 left-0 right-0 z-0 "
    src="../../static/macbook-chair.jpg"
    alt="Apple Macbook Dark"
    placeholder="blurred"
    />

  return (
    <>
      <Seo
      title="Prodotti | FvgLab - Scopri il catalogo dei prodotti informatici personalizzabili per il tuo business" 
      description={"Esplora il catalogo prodotti FvgLab per trovare la soluzione informatica personalizzata per il tuo business. Offriamo servizi e prodotti di alta qualità, dalle applicazioni software alle soluzioni di sicurezza informatica. Contattaci oggi per una consulenza."}
      />

      <Layout>
        <SimpleBanner title="Sfoglia il nostro catalogo" back={children} />
        <section className="relative py-20 md:py-40 px-9 md:px-20">
          {/* {title || introduction ? (
            <div className="mx-auto max-w-7xl">
              <div className="mb-10 max-w-2xl">
                {title && <h2>{title}</h2>}
                {introduction && <p>{introduction}</p>}
              </div>
            </div>
          ) : null} */}

          <div className="mx-auto max-w-7xl snap-mandatory grid grid-cols-1 lg:grid-cols-3 gap-10">
            {Products.map((node, index) => {
              return <FeaturedProduct feature={node} key={index} />              
            })}
          </div>

        </section>

      </Layout>
    </>
  )
}

export default contact
